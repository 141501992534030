import React from "react";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

interface LayersDownloadButtonProps {
    layerId: string;
  }

export const LayersDownloadButton: React.FC<LayersDownloadButtonProps> = (props: any) => {
    const { layerId } = props;
    const buttonClick = (e: any) => {
      const params = {
          layerId: e.target.id,
          format: e.target.text,
      }
      axios.post("/api/export_layer", params).then(r => {
        alert('Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_DOWNLOAD_LAYERS')
            }
        ).catch(error => {
            alert(`Download layer error! ${ error.response.data.error }`)
        });
    };
    return (
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
                  Скачать
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={buttonClick} id={layerId}>
                    shp
                  </Dropdown.Item>
                  <Dropdown.Item onClick={buttonClick} id={layerId}>
                    geojson
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
    );
  };
import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCheckbox } from "./firmsActions/checkboxChange";
import { pushButton } from "./firmsActions/buttonPush";
import { actionFirmsRegions } from "./firmsActions/valueChoose";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const Firms: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[14].downloadChecked);

  const buttonClick = (e: any) => {
    props.setPushButton(true);

    axios
      .post("/api/firms", props.layers[14])
      .then((r) => {
        alert(
          "Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_FIRMS"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Firms error! ${error.response.data.error}`);
      });
  };
  const valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Россия и Азия":
        props.setValue("asia_rus");
        if (props.layers[14].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Европа":
        props.setValue("europe");
        if (props.layers[14].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
    }
    console.log(props.layers);
  };

  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[14].csv_om) {
      case false:
        props.setCheckbox(true);
        break;
      case true:
        props.setCheckbox(false);
    }
  };

  return (
    <div className="firms">
      <Card.Body>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Выберите территорию</Form.Label>
          <Form.Control size="sm" as="select" onChange={valueChange}>
            <option>Россия и Азия</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formBasicCheckboxMeteored">
          <Form.Check
            type="checkbox"
            label="выгрузить для Overmap"
            onChange={checkboxCheck}
          />
        </Form.Group>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://firms.modaps.eosdis.nasa.gov/"
          target="_blank"
        >
          Открыть FIRMS
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[14].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setValue: actionFirmsRegions,
      setCheckbox: actionCheckbox,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Firms);

import React from 'react';
import { Button, Accordion, Card, Badge, Form } from 'react-bootstrap';
import SpinnerForLoading from '../../../additional/Spinner/SpinnerForLoading';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./firesAction/buttonPush";
import { actionSite } from "./firesAction/downloadFromSite";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Fires: React.FC = (props: any) => {
    let fileToUpload: File;

    const changeHandler = (event: any) => {
        console.log(props.layers[8]);
        fileToUpload = event.target.files[0];
    }
    const setCheckbox = (e:React.ChangeEvent<HTMLInputElement>) => {
        console.log(props.layers[8]);
        switch (props.layers[8].site) {
            case false:            
                props.setCheckbox(true);
                break;
            case true:
                props.setCheckbox(false);
        }
        console.log(props.layers[8]);
    }

    const buttonClick = (e: any) => {
        props.setPushButton(true);
        if (props.layers[8].site === false) {
            if (fileToUpload == null) {
                alert("Необходимо сначала выбрать файл");
                props.setPushButton(false);
                return
            }
            const formData = new FormData();
            formData.append('file', fileToUpload, fileToUpload.name);
            axios.post("/api/fires?fromsite=0", formData).then(r => {
                    alert('Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\SCRIPTS_SOFT_PLUGINGS\\Fires')
                    props.setPushButton(false);
                }
            ).catch(error => {
                props.setPushButton(false);
                alert(`Fires error! ${ error.response.data.error }`)
            });
        }
        else {
            axios.post("/api/fires?fromsite=1").then(r => {
                alert('Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\SCRIPTS_SOFT_PLUGINGS\\Fires')
                props.setPushButton(false);
            }
            ).catch(error => {
                props.setPushButton(false);
                alert(`Fires error! ${ error.response.data.error }`)
            });
        }
        }

    return (
        <div className='fires'>
            {/*<Card>
                <Accordion.Toggle as={Card.Header} eventKey="7">
                    Данные по пожарам
                    {props.layers.map( (l:any) => {
                    let id:string = l.id;
                    let download:boolean = l.downloadChecked
                    if (id === "fires" && download === true) {return ( <SpinnerForLoading key={id}/> )}              
                })}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
            */}
                    <Card.Body>
                    <Form.Group controlId="formBasicCheckbox1">
                         <Form.Check type="checkbox" label="обновить на основе данных сайта" onChange={setCheckbox}/>
                    </Form.Group>
                        <form className="form-group">
                            <label htmlFor="formcontrolFile">Выберите файл для загрузки</label>
                            <input
                                disabled={props.layers[8].site}
                                id="formcontrolFile"
                                className="form-control-file"
                                type="file"
                                name="file"
                                onChange={changeHandler}/>
                        </form>
                        <Button
                            size="sm"
                            type="button"
                            variant="light"
                            href="https://docs.google.com/document/d/1Cmwjzi1hagfOY10pc5ZPdfwUCVTrnqXeiinY5Lqn7Z4/edit?usp=sharing"
                            target="_blank">
                            Инструкция
                        </Button>
                        <Button 
                            size="sm"
                            type="button"
                            variant="light"
                            href="https://aviales.ru/"
                            target="_blank">
                                Открыть Авиалес
                        </Button>
                        <Button
                            size="sm"
                            type="button"
                            disabled={props.layers[8].downloadChecked}
                            onClick={buttonClick}>
                            Загрузить файл</Button>
                    </Card.Body>
                {/*</Accordion.Collapse>
            </Card>*/}
        </div>
    );
}
const mapStateToProps = (state:any) => {
    return {
        layers: state.layers
    };
};
const matchDispatchToProps = (dispatch:any) => {
    return bindActionCreators({
        setPushButton: pushButton,
        setCheckbox: actionSite,
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Fires);

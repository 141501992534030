import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
//import SpinnerForLoading from '../../../additional/Spinner/SpinnerForLoading';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./gismeteoActions/buttonPush";
import { actionArea } from "./gismeteoActions/areaChoose";
import { actionCheckboxCloudRender } from "./gismeteoActions/checkboxChange";
import { actionZoom } from "../Radar/radarActions/changeZoom";
import { actionCenter } from "../Radar/radarActions/changeCenter";
import { useSelector } from "react-redux";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const Gismeteo: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[2].downloadChecked);

  const users = useSelector((state) => props.users);

  const buttonClick = (e: any) => {
    props.setPushButton(true);

    const token = users.currentUser.token;
    const headers = {
      token: token,
    };

    axios
      .post(
        `/api/gismeteo?cloud_render=${props.layers[2].cloud_render}`,
        props.layers[2],
        {
          headers: headers,
        }
      )
      .then((r) => {
        alert(
          "Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_GISMETEO_SEQ"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Gismeteo error! ${error.response.data.error}`);
      });
  };
  const areaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Московская область":
        //console.log('etr')
        props.setArea("mo");
        props.setMapZoom(6);
        props.setMapCenter([56.01, 37.01]);
        if (props.layers[2].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Москва":
        props.setArea("msk");
        props.setMapZoom(8);
        props.setMapCenter([55.32, 37.01]);
        if (props.layers[2].downloadChecked === true) {
          props.setPushButton(false);
        }
    }
    console.log(props.layers);
  };
  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[2].extent) {
      case false:
        switch (props.layers[2].area) {
          case "mo":
            props.setMapZoom(6);
            props.setMapCenter([56.01, 37.01]);
            break;
          case "msk":
            props.setMapZoom(8);
            props.setMapCenter([55.32, 37.01]);
            break;
        }
        props.setCheckbox(true);
        break;
      case true:
        props.setCheckbox(false);
    }
  };
  const cloudRenderСheckboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[2].cloud_render) {
      case false:
        props.setCheckbox(true);
        break;
      case true:
        props.setCheckbox(false);
    }
    console.log(props.layers[2]);
  };

  return (
    <div className="gismeteo">
      {/*<Card>
                 <Accordion.Toggle as={Card.Header} eventKey="1">
                 Гисметео
                 {props.layers.map( (l:any) => {
                    let id:string = l.id;
                    let download:boolean = l.downloadChecked
                    if (id === "gismeteo" && download === true) {return ( <SpinnerForLoading key={id}/> )}              
                })}
                 </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">*/}
      <Card.Body>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Выберите территорию</Form.Label>
          <Form.Control size="sm" as="select" onChange={areaChange}>
            <option>Московская область</option>
            <option>Москва</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="выгрузить под облачный рендер"
            onChange={cloudRenderСheckboxCheck}
          />
        </Form.Group>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://crm.emg24.ru/workgroups/group/14/wiki/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F+%D0%BA+%D0%93%D0%B8%D1%81%D0%BC%D0%B5%D1%82%D0%B5%D0%BE+%28%D0%90%D1%82%D0%BC%D0%BE%D1%81%D1%84%D0%B5%D1%80%D0%B0%29/"
          target="_blank"
        >
          Инструкция
        </Button>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://www.gismeteo.ru/nowcast-moscow-4368/"
          target="_blank"
        >
          Открыть Гисметео
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[2].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
      {/*</Accordion.Collapse>
             </Card>*/}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setArea: actionArea,
      setMapZoom: actionZoom,
      setMapCenter: actionCenter,
      setCheckbox: actionCheckboxCloudRender,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Gismeteo);

import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./yandexActions/buttonPush";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const YandexGeocoder = (props) => {

  const [todos, setTodos] = useState([]);
  const [todo, setTodo] = useState("");

  const buttonClick = (e) => {
    console.log(props.layers[16]);
    props.setPushButton(true);
    console.log(props.layers[16]);
    axios
      .post("/api/geocoding", {addresses: todos})
      .then((r) => {
        alert(
          "Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_GEOCODING_EXP"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Geocoding error! ${error.response.data.error}`);
      });
  };

  const addTodo = () => {
    if (todo !== "") {
      setTodos([...todos, todo]);
      setTodo("");
    }
  };

  const deleteTodo = (text) => {
    const newTodos = todos.filter((todo) => {
      return todo !== text;
    });
    setTodos(newTodos);
  };

  return (
    <div className="yandex">
      <Card.Body>
      <InputGroup size="sm" className="mb-3">
        <Form.Control
          placeholder="Добавить адрес"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          onChange={(e) => {
            setTodo(e.target.value);
          }}
        />
        <Button size="sm"
          type="button" onClick={addTodo}>
          Добавить
        </Button>
      </InputGroup>

        {todos?.length > 0 ? (
          <ul className="todo-list">
            {todos.map((todo, index) => (
              <div className="todo">
                <li key={index}> {todo} </li>
                {/*
                <Button
                  size="sm"
                  onClick={() => {
                    deleteTodo(todo);
                  }} 
                >
                  Удалить
                </Button>
                */}
              </div>
            ))}
          </ul>
        ) : (
          <div className="empty">
            <p></p>
          </div>
        )}
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://docs.google.com/document/d/1sGrtKGdwANEcjF2YwumhDFbSFc5gUKGthkkvHAZc6tI/edit?usp=sharing"
          target="_blank"
        >
          Инструкция
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[6].downloadChecked}
          onClick={buttonClick}
        >
          Создать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(YandexGeocoder);

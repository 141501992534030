import { combineReducers } from "redux";
import LayersReducers from "./layers";
import userReducer from "./users";

const allReducers = combineReducers({
  layers: LayersReducers,
  users: userReducer,
});

export default allReducers;

import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LayerItem } from "../../common-types";
import { LayersDownloadButton } from "../layers-download/layer-download-button";
import { actionLayersTocVisible } from "./actionLayersTocVisible";

interface LayersProps {
  item: LayerItem;
}

const Layers: React.FC<LayersProps> = (props: any) => {
  const { item } = props;
  const { id, name } = item;

  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.id) {
      case "gismeteo":
        props.setCheckbox(e.target.id, !props.layers[2].lyr_visible);
        break;
      case "corona":
        props.setCheckbox(e.target.id, !props.layers[3].lyr_visible);
        break;
      case "temperature":
        props.setCheckbox(e.target.id, !props.layers[10].lyr_visible);
        break;
      case "hydro":
        props.setCheckbox(e.target.id, !props.layers[7].lyr_visible);
        break;
      case "firms":
        props.setCheckbox(e.target.id, !props.layers[14].lyr_visible);
        break;
    }
  };
  return (
    <Row>
      <Col md={8}>
        <Form.Group controlId={id}>
          <Form.Check
            type="checkbox"
            defaultChecked={true}
            label={name}
            onChange={checkboxCheck}
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <LayersDownloadButton layerId={id} />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setCheckbox: actionLayersTocVisible,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Layers);

import React, { useState } from "react";
import { Dropdown, Accordion, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./actionsLayersTOC/buttonPush";
import "bootstrap/dist/css/bootstrap.min.css";
import Layers from "../../../modules/layers-toc/layers-toc";
import { PassThrough } from "stream";

const LayersTOC: React.FC = (props: any) => {
  const buttonClick = (e: any) => {
    console.log(e.target.id);
    props.setPushButton(e.target.id);
  };
  return (
    <div className="layers">
      {/*<Card>
        
        <Accordion.Toggle as={Card.Header} eventKey="1">
          Список слоёв
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
  */}
      <Card.Body>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
            Добавить слой
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={buttonClick} id="corona">
              Коронавирус (регионы России)
            </Dropdown.Item>
            <Dropdown.Item onClick={buttonClick} id="hydro">
              Опасные метеоявления (по УГМС)
            </Dropdown.Item>
            <Dropdown.Item onClick={buttonClick} id="gismeteo">
              Осадки Гисметео (Атмо)
            </Dropdown.Item>
            <Dropdown.Item onClick={buttonClick} id="firms">
              Пожары FIRMS (Россия и Азия)
            </Dropdown.Item>
            <Dropdown.Item onClick={buttonClick} id="temperature">
              Температура в Москве (Атмо)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <br />
        {props.layers.map((l: any) => {
          if (l.lyr_render === true) {
            console.log("lyr layer", l.id);
            return <Layers item={l} key={l.id} />;
          }
        })}
      </Card.Body>
      {/*</Accordion.Collapse>
      </Card>*/}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(LayersTOC);

import React, { useState } from "react";
import { Button, Accordion, Card, Form } from "react-bootstrap";
import SpinnerForLoading from "../../../additional/Spinner/SpinnerForLoading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./meteoredActions/buttonPush";
import { actionArea } from "./meteoredActions/areaChoose";
import { actionWeatherValue } from "./meteoredActions/valueChoose";
import { actionCheckbox } from "./meteoredActions/checkboxChange";
import { actionZoom } from "../Radar/radarActions/changeZoom";
import { actionCenter } from "../Radar/radarActions/changeCenter";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { actionValue } from "../Coronavirus/coronaActions/valueChoose";

const Meteored: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[5].downloadChecked);

  const buttonClick = (e: any) => {
    props.setPushButton(true);

    axios
      .post("/api/meteored", props.layers[5])
      .then((r) => {
        alert(
          "Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_METEORED_SEQ"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Meteored error! ${error.response.data.error}`);
      });
  };
  const valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Температура":
        props.setValue("temperature");
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Осадки":
        props.setValue("precipitation");
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
    }
    console.log(props.layers);
  };
  const areaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "ЕТР в проекции Меркатора":
        //console.log('etr')
        props.setArea("etr_mercator");
        props.setMapZoom(3);
        props.setMapCenter([56.01, 57.01]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "ЕТР в проекции Ламберта":
        props.setArea("etr_conic");
        props.setMapZoom(2);
        props.setMapCenter([50.32, 57.01]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "ЕТР в азимутальной (Россия генерализованная)":
        props.setArea("etr_gener_conic");
        props.setMapZoom(1);
        props.setMapCenter([45.32, 57.0]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "ЕТР (Радар для Погоды24)":
        props.setArea("etr_radar");
        props.setMapZoom(3);
        props.setMapCenter([62.01, 47.0]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Центральная часть ЕТР (Радар для Погоды24)":
        props.setArea("etr_center_radar");
        props.setMapZoom(3);
        props.setMapCenter([52.354939, 41.997425]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Кавказ (Радар для Погоды24)":
        props.setArea("caucase_radar");
        props.setMapZoom(5);
        props.setMapCenter([44.93, 42.8]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Московская область (Радар для Погоды24)":
        props.setArea("mo_radar");
        props.setMapZoom(5);
        props.setMapCenter([55.5, 37.8]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Дальний восток (Радар для Погоды24)":
        props.setArea("dv_radar");
        props.setMapZoom(3);
        props.setMapCenter([55.212247, 153.285682]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Приморье, Корея, Япония (Радар для Погоды24)":
        props.setArea("jp_radar");
        props.setMapZoom(3);
        props.setMapCenter([55.212247, 153.285682]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Дальний восток от Байкала до Сахалина (Радар для Погоды24)":
        props.setArea("basa_radar");
        props.setMapZoom(3);
        props.setMapCenter([55.212247, 153.285682]);
        if (props.layers[5].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
    }
    console.log(props.layers);
  };
  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[5].extent) {
      case false:
        switch (props.layers[5].area) {
          case "etr_mercator":
            props.setMapZoom(3);
            props.setMapCenter([56.01, 57.01]);
            break;
          case "etr_conic":
            props.setMapZoom(2);
            props.setMapCenter([50.32, 57.01]);
            break;
          case "etr_gener_conic":
            props.setMapZoom(1);
            props.setMapCenter([45.32, 57.01]);
            break;
        }
        props.setCheckbox(true);
        break;
      case true:
        props.setCheckbox(false);
    }
  };

  return (
    <div className="meteored">
      {/*} <Card>
                 <Accordion.Toggle as={Card.Header} eventKey="4">
                 Погода Meteored
                 {props.layers.map( (l:any) => {
                    let id:string = l.id;
                    let download:boolean = l.downloadChecked
                    if (id === "meteored" && download === true) {return ( <SpinnerForLoading key={id}/> )}              
                })}
                 </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">*/}
      <Card.Body>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Выберите показатель</Form.Label>
          <Form.Control size="sm" as="select" onChange={valueChange}>
            <option>Температура</option>
            <option>Осадки</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Выберите основу и охват</Form.Label>
          <Form.Control size="sm" as="select" onChange={areaChange}>
            <option>ЕТР в проекции Меркатора</option>
            <option>ЕТР в проекции Ламберта</option>
            <option>ЕТР в азимутальной (Россия генерализованная)</option>
            <option>ЕТР (Радар для Погоды24)</option>
            <option>Центральная часть ЕТР (Радар для Погоды24)</option>
            <option>Кавказ (Радар для Погоды24)</option>
            <option>Московская область (Радар для Погоды24)</option>
            <option>Дальний восток (Радар для Погоды24)</option>
            <option>
              Дальний восток от Байкала до Сахалина (Радар для Погоды24)
            </option>
            <option>Приморье, Корея, Япония (Радар для Погоды24)</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formBasicCheckboxMeteored">
          <Form.Check
            type="checkbox"
            label="показать основу и охват на карте"
            onChange={checkboxCheck}
          />
        </Form.Group>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://docs.google.com/document/d/1kIXlU0E-D7hzoZEqSASebV99Nmq7e_gVPX3RDfN2pnU/edit?usp=sharing"
          target="_blank"
        >
          Инструкция
        </Button>
        <Button
          size="sm"
          type="button"
          variant="light"
          href="https://www.pogoda.com/weather-maps/temp2m-rusia.html"
          target="_blank"
        >
          Открыть Meteored
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[5].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
      {/*</Accordion.Collapse>
             </Card>*/}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setArea: actionArea,
      setValue: actionWeatherValue,
      setCheckbox: actionCheckbox,
      setMapZoom: actionZoom,
      setMapCenter: actionCenter,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Meteored);

import React, { useState } from "react";
import { Button, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushButton } from "./vyboryActions/buttonPush";
import { actionArea } from "./vyboryActions/areaChoose";
import { actionStyle } from "./vyboryActions/styleChoose";
import { actionValue } from "./vyboryActions/valueChoose";
import { actionScale } from "./vyboryActions/scaleChoose";
import { actionColors } from "./vyboryActions/colorChoose";
import { actionCheckbox } from "./vyboryActions/checkboxChange";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const Vybory: React.FC = (props: any) => {
  const [checked, setChecked] = useState(props.layers[12].downloadChecked);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const buttonClick = (e: any) => {
    props.setPushButton(true);
    console.log(props.layers[12]);

    axios
      .post("/api/election", props.layers[12])
      .then((r) => {
        alert(
          "Все материалы лежат по адресу:\nW:\\Big_Project\\__MAPS\\!Py\\_VYBORY2021"
        );
        props.setPushButton(false);
      })
      .catch((error) => {
        props.setPushButton(false);
        alert(`Vybory error! ${error.response.data.error}`);
      });
  };
  const areaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Явка":
        //console.log('etr')
        props.setArea("presence");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Партии":
        props.setArea("gd");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
    }
    console.log(props.layers);
  };
  const styleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "Единая Россия":
        props.setStyle("ed_ros");
        props.setColors([
          "#c9cfec",
          "#82a0dd",
          "#4d58f4",
          "#030fae",
          "#000256",
        ]);
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "КПРФ":
        props.setStyle("kprf");
        props.setColors([
          "#f0c8c8",
          "#ff9393",
          "#f43729",
          "#c40000",
          "#860202",
        ]);
        //props.setColors({0:'#f0c8c8', 1:'#ff9393', 2: '#f43729', 3: '#c40000', 4:'#860202'});
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Справедливая Россия":
        props.setStyle("sprav_ros");
        props.setColors([
          "#faedd5",
          "#ffe084",
          "#ffb400",
          "#f97c00",
          "#d75701",
        ]);
        //props.setColors({0:'#faedd5', 1:'#ffe084', 2: '#ffb400', 3: '#f97c00', 4:'#d75701'});
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "ЛДПР":
        props.setStyle("ldpr");
        props.setColors([
          "#d2e7fc",
          "#87bdf4",
          "#308ce8",
          "#015dac",
          "#003a66",
        ]);
        //props.setColors({0:'#d2e7fc', 1:'#87bdf4', 2: '#308ce8', 3: '#015dac', 4:'#003a66'});
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Яблоко":
        props.setStyle("yabloko");
        props.setColors([
          "#d0f8d0",
          "#83eb83",
          "#33cc33",
          "#009000",
          "#075f00",
        ]);
        //props.setColors({0:'#d0f8d0', 1:'#83eb83', 2: '#33cc33', 3: '#009000', 4:'#075f00'});
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Партия роста":
        props.setStyle("part_rosta");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Гражданская платформа":
        props.setStyle("grazhd_platf");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Коммунисты России":
        props.setStyle("kommun_rossiyi");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Партия пенсионеров":
        props.setStyle("part_pensioner");
        props.setColors([
          "#ffd5e0",
          "#f66d91",
          "#e82255",
          "#b00153",
          "#840041",
        ]);
        //props.setColors({0:'#ffd5e0', 1:'#f66d91', 2: '#e82255', 3: '#b00153', 4:'#840041'});
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Партия Родина":
        props.setStyle("part_rodina");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Партия Зелёные":
        props.setStyle("zelyonye");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "РПСС":
        props.setStyle("rpss");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Зелёная альтернатива":
        props.setStyle("zel_altern");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Новые люди":
        props.setStyle("novye_lyudi");
        props.setColors([
          "#d3fefd",
          "#abf1f0",
          "#47c2c0",
          "#018785",
          "#005c5b",
        ]);
        //props.setColors({0:'#d3fefd', 1:'#abf1f0', 2: '#47c2c0', 3: '#018785', 4:'#005c5b'});
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Победитель №1 в регионе":
        props.setStyle("leader_1");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Победитель №2 в регионе":
        props.setStyle("leader_2");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case "Победитель №3 в регионе":
        props.setStyle("leader_3");
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
    }
    console.log(props.layers);
  };

  const checkboxCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (props.layers[12].csv_om) {
      case false:
        props.setCheckbox(true);
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
        break;
      case true:
        props.setCheckbox(false);
        if (props.layers[12].downloadChecked === true) {
          props.setPushButton(false);
        }
    }
  };
  const scaleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[0] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const scaleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[1] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const scaleChange3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[2] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const scaleChange4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    var scale = props.layers[12].scale;
    scale[3] = Number(e.target.value);
    props.setScale(scale);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const colorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var colors = props.layers[12].colors;
    colors[Number(e.target.id)] = e.target.value;
    props.setColors(colors);
    console.log(props.layers[12].colors);
    if (props.layers[12].downloadChecked === true) {
      props.setPushButton(false);
    }
  };
  const addName = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    let name = e.target.value.toString();
    props.setName(name);
    if (props.layers[6].downloadChecked === true) {
      props.setPushButton(false);
    }
  };

  return (
    <div className="vybory">
      <Card.Body>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Тип карты</Form.Label>
          <Form.Control size="sm" as="select" onChange={areaChange}>
            <option>Явка</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label>Данные по</Form.Label>
          <Form.Control size="sm" as="select" onChange={styleChange}>
            <option>Явка по регионам</option>
            {/*
            <option>КПРФ</option>
            <option>Справедливая Россия</option>
            <option>ЛДПР</option>
            <option>Яблоко</option>
            <option>Партия роста</option>
            <option>Гражданская платформа</option>
            <option>Коммунисты России</option>
            <option>Партия пенсионеров</option>
            <option>Партия Родина</option>
            <option>Партия Зелёные</option>
            <option>РПСС</option>
            <option>Зелёная альтернатива</option>
            <option>Новые люди</option>
            <option>Победитель №1 в регионе</option>
            <option>Победитель №2 в регионе</option>
  <option>Победитель №3 в регионе</option>*/}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlSelect3">
          <Form.Label>Шкала</Form.Label>
        </Form.Group>
        <InputGroup className="mb-3" size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text>менее</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder={props.layers[12].scale[0]}
            onChange={scaleChange1}
          />
          <FormControl
            placeholder={props.layers[12].scale[1]}
            onChange={scaleChange2}
          />
          <FormControl
            placeholder={props.layers[12].scale[2]}
            onChange={scaleChange3}
          />
          <FormControl
            placeholder={props.layers[12].scale[3]}
            onChange={scaleChange4}
          />
          <InputGroup.Prepend>
            <InputGroup.Text>более</InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
        <Form.Label htmlFor="exampleColorInput">Цветовая шкала</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            type="color"
            id="0"
            value={props.layers[12].colors[0]}
            defaultValue={props.layers[12].colors[0]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          <Form.Control
            type="color"
            id="1"
            value={props.layers[12].colors[1]}
            defaultValue={props.layers[12].colors[1]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          <Form.Control
            type="color"
            id="2"
            value={props.layers[12].colors[2]}
            defaultValue={props.layers[12].colors[2]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          <Form.Control
            type="color"
            id="3"
            value={props.layers[12].colors[3]}
            defaultValue={props.layers[12].colors[3]}
            title="Выберите цвет"
            onChange={colorChange}
          />
          <Form.Control
            type="color"
            id="4"
            value={props.layers[12].colors[4]}
            defaultValue={props.layers[12].colors[4]}
            title="Выберите цвет"
            onChange={colorChange}
          />
        </InputGroup>
        <Form.Group controlId="formBasicCheckboxVyboryCSV">
          <Form.Check
            type="checkbox"
            label="csv для Overmap"
            onChange={checkboxCheck}
          />
        </Form.Group>
        <Button
          size="sm"
          type="button"
          disabled={props.layers[12].downloadChecked}
          onClick={buttonClick}
        >
          Скачать
        </Button>
      </Card.Body>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setPushButton: pushButton,
      setArea: actionArea,
      setStyle: actionStyle,
      setValue: actionValue,
      setCheckbox: actionCheckbox,
      setScale: actionScale,
      setColors: actionColors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Vybory);

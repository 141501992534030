import axios from "axios";
import { setUser } from "../reducers/users";

export const login = (email: any, password: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`/api/login`, {
        email,
        password,
      });
      dispatch(setUser(response.data));
      localStorage.setItem("token", response.data.token);
    } catch (e) {
      alert(e.response.data.message);
    }
  };
};

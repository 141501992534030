import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Nav from "react-bootstrap/Nav";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BasemapTimeline from "../additional/timeline/timeline";
import { actionToolboxNav } from "../Toolbox/actions/toolbox-nav-action";
import Basemaps from "./Basemaps/Basemaps";
import Basemap from "./Cards/Basemap/Basemap";
import LayersTOC from "./Layers/Layers";
import "./Toolbox.css";
import Tools from "./Tools/Tools";
import TextLinkExample from "./Navigation/Navigation";
import { useSelector } from "react-redux";

const Toolbox: React.FC = (props: any) => {
  const toolChange = (eventKey: any) => {
    props.setContent(eventKey.target.id);
  };
  const isBasemapTimeline = props.layers[15].active === "base_map";
  const isLayers = props.layers[15].active === "layers";
  const isTools = props.layers[15].active === "tools";
  const isExport = props.layers[15].active === "export";
  const isAuth = useSelector((state) => props.users.isAuth);
  //const isAuth = props.users.isAuth;
  console.log(isAuth);

  return (
    <div className="toolbox">
      <TextLinkExample />
      <Nav variant="tabs" defaultActiveKey="map">
        <Nav.Item>
          <Nav.Link eventKey="map" id="base_map" onClick={toolChange}>
            Карта
          </Nav.Link>
        </Nav.Item>
        {isAuth ? (
          <Nav.Item>
            <Nav.Link eventKey="layers" id="layers" onClick={toolChange}>
              Слои
            </Nav.Link>
          </Nav.Item>
        ) : null}
        <Nav.Item>
          <Nav.Link eventKey="tools" id="tools" onClick={toolChange}>
            Инструменты
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="export" id="export" onClick={toolChange}>
            Экспорт
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {isBasemapTimeline ? (
        <>
          <div className="timeline">
            <BasemapTimeline />
          </div>
          <Basemaps />
        </>
      ) : null}
      {isLayers ? <LayersTOC /> : null}
      {isTools ? <Tools /> : null}
      {isExport ? <Basemap /> : null}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    layers: state.layers,
    users: state.users,
  };
};
const matchDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setContent: actionToolboxNav,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(Toolbox);
